:root {
  --primary-font-color: #1d2129;
  --gray-font-color: #4e5969;
  --title-font-color: #000f2d;
  --blue-color: #165dff;
  --page-background-color: #f9fbfc;
  --gradient-background-color: linear-gradient(
    -45.56deg,
    rgba(5.31, 120.17, 255, 1) 6.351%,
    rgba(21.25, 212.93, 255, 1) 109.129%
  );
  --title-font-size: 48px;
  --subtitle-font-size: 32px;
  --strong-font-size: 24px;
  --text-font-size: 20px;
  --broder-radius: 2px;
  --min-width: 640PX;
}

body {
  /* color: var(--primary-font-color); */
  /* min-width: 1366px; */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: var(--min-width);
  overflow-x: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
