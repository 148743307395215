.feature_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  height: 384px;

  .title {
    color: var(--primary-font-color);
    font-size: 64px;
    font-weight: bolder;
  }

  .desc {
    color: var(--gray-font-color);
    font-size: 20px;
    margin-top: 24px;
  }
}
