.container {
  padding: 60px 100px 48px;
  background-color: #0a0c11;

  .column {
    display: flex;
    flex-direction: column;

    .content {
      flex: 1;
      text-align: left;
      margin-bottom: 12px;
      // padding: 32px 0 12px 32px;

      .title {
        color: var(--blue-color);
        font-size: var(--title-font-size);
        font-weight: bolder;
        margin-bottom: 12px;
      }

      .desc {
        color: #fff;
        font-size: var(--subtitle-font-size);
        font-weight: bolder;
        margin-bottom: 32px;
      }

      .list {
        color: var(--gray-font-color);
        font-weight: 500;
        display: flex;
        flex-flow: row wrap;

        .cell {
          width: 50%;
          color: #c9cdd4;
          font-size: var(--text-font-size);
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 48px;

          img {
            width: 48px;
            margin-right: 32px;
          }
        }
      }
    }

    .image_cover {
      width: 1720px;
      height: 967px;
    }
  }
}
