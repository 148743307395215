.mySwiper {
  padding: 0 40px 100px !important;

  .swiper_item {
    padding: 0 50px;
    // height: 360px !important;
    overflow: overlay;

    .swiper_item_title {
      background: linear-gradient(-45.56deg, rgba(5.31, 120.17, 255, 1), rgba(21.25, 212.93, 255, 1));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 38px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 24px;
    }

    .swiper_item_content {
      color: var(--gray-font-color);
      font-size: var(--text-font-size);
    }
  }

  :global(.swiper-pagination-progressbar) {
    width: 300px !important;
    top: auto !important;
    bottom: 50px;
    right: 0;
    margin: auto;
  }
}
