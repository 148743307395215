.title {
  color: var(--primary-font-color);
  font-size: 64px;
  font-weight: bolder;
  align-self: flex-start;
  padding: 90px 200px 0 188px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.date {
  color: var(--primary-font-color);
  font-size: 32px;
  font-weight: 500;
  align-self: flex-start;
  margin-top: auto;
  padding: 0 0 24px 188px;
}

.content {
  padding: 50px 100px;
  overflow: hidden;

  p {
    // font-size: 20px;
  }

  img {
    max-width: 100%;
  }
}
