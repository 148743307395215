.article {
  padding: 60px 100px;

  .title {
    color: var(--primary-font-color);
    font-size: var(--title-font-size);
    margin-bottom: 16px;
  }

  .content {
    color: var(--gray-font-color);
    font-size: 22px;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  padding: 168px 100px 48px;
  background-size: cover;
  background-position: center;

  .item {
    width: 800px;
    background-color: #fff;
    padding: 48px 48px 48px 32px;
    margin-bottom: 120px;

    .item_title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .icon {
        width: 80px;
        height: 80px;
        margin-right: 24px;
      }

      .title {
        background: linear-gradient(-45.56deg, rgba(5.31, 120.17, 255, 1), rgba(21.25, 212.93, 255, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 32px;
        font-weight: 700;
      }
    }

    .item_content {
      color: var(--gray-font-color);
      font-size: 20px;
      padding-left: 104px;
    }
  }
}
