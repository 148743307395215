.main {
  padding: 100px 120px;

  .content {
    background-color: var(--primary-font-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    padding: 48px;

    .item {
      flex: 1;
      color: #fff;
      text-align: center;

      .title {
        font-size: 32px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 32px;
      }

      .details {
        display: flex;
        flex-direction: column;

        span {
          font-size: 20px;
        }
      }
    }

    .item:nth-child(2) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        background-color: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        background-color: #fff;
      }
    }
  }
}
