.container {
  height: 484px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .content {
    margin-left: 188px;
    padding-top: 194px;
    color: #fff;

    .title {
      font-size: 64px;
      font-weight: bolder;
      margin-bottom: 140px;
    }

    .breadcrumbs {
      font-size: 20px;

      span {
        cursor: pointer;
      }
    }
  }
}
