.list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 32px;

  .item {
    min-width: 0;
    flex: 0 0 calc(50% - 32px);
    margin: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    cursor: pointer;

    .image_cover {
      width: 345px;
      height: 345px;
      margin: 20px 0 50px;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 80%;

      > div {
        max-width: 100%;
        text-align: center;
      }

      .title {
        font-size: var(--title-font-size);
        font-weight: bolder;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 12px;
      }

      .model {
        color: #86909c;
        font-size: var(--text-font-size);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 24px;
      }

      .description {
        color: var(--primary-font-color);
        font-size: var(--text-font-size);
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 32px;
      }

      .detailButton {
        color: #fff;
        font-size: var(--text-font-size);
        background-color: var(--blue-color);
        border-radius: var(--border-radius);
        padding: 16px 44px;
      }
    }
  }
}
