.wrapper {
  cursor: pointer;
  height: 560px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;

  .title {
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: 0px;
  }

  .subtitle {
    font-size: 22px;
    margin: 32px 0 120px;
  }

  .button {
    font-size: 22px;
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 14px 124px;
    cursor: pointer;
  }
}
