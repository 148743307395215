.image {
  width: 100%;
  // max-height: 65vw;
  // min-height: 40vw;
  object-fit: cover;
  object-position: bottom;
}

.caption {
  color: #fff;
  bottom: 115px !important;
  padding: 0 !important;
  max-width: 622px;
  margin: auto;

  h3 {
    font-size: var(--strong-font-size);
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 60px;
  }

  div {
    display: inline-block;
    line-height: 46px;
    font-size: var(--primary-font-size);
    background-color: var(--blue-color);
    text-align: center;
    margin: 0 auto;
    padding: 0 24px;
  }
}
