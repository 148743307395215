.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 220px;
  cursor: pointer;

  .logo {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .name {
    width: 134px;
  }
}
