.article {
  padding: 60px 100px;

  .title {
    background: linear-gradient(-45.56deg, rgba(5.31, 120.17, 255, 1), rgba(21.25, 212.93, 255, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--subtitle-font-size);
    font-weight: bolder;
    margin-bottom: 32px;
  }

  .content {
    color: var(--gray-font-color);
    font-size: var(--text-font-size);
  }
}

.banner {
  width: 100%;
  padding: 300px 100px 68px;
  background-size: contain;
  background-repeat: no-repeat;

  .list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 100px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;

    .item {
      width: calc(33.33% - 84px);
      height: 50%;
      margin-bottom: 40px;

      .item_title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .icon {
          width: 80px;
          height: 80px;
          margin-right: 24px;
        }

        .title {
          background: linear-gradient(-45.56deg, rgba(5.31, 120.17, 255, 1), rgba(21.25, 212.93, 255, 1));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          font-size: var(--subtitle-font-size);
          font-weight: bolder;
        }
      }

      .item_content {
        color: var(--gray-font-color);
        font-size: var(--text-font-size);
      }
    }
  }
}
