.page_wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page_main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--page-background-color);
  }
}
