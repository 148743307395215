.category {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-top: 72px;

  .item {
    color: #fff;
    border-radius: var(--border-radius);
    background-color: #000;
    padding: 8px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 12px;

    & + .item {
      margin-left: 48px;
    }

    &.active {
      // background: linear-gradient(-45.56deg, rgba(5.31, 120.17, 255, 1) 4.591%, rgba(21.25, 212.93, 255, 1) 107.693%);
      background-color: var(--blue-color);
    }
  }
}
