.contact {
  height: 130px;
  line-height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #e8f3ff;
  color: var(--blue-color);
  font-size: var(--subtitle-font-size);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: var(--blue-color);
  }

  .image_cover {
    width: 48px;
    margin-right: 16px;
  }
}
