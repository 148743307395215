.content {
  padding: 60px 10%;

  img {
    max-width: 100%;
  }

  p {
    margin-bottom: 8px;
  }
}
