.container {
  border-top: 48px solid #fff;
  border-bottom: 48px solid #fff;
  display: flex;
  flex-direction: column;

  .title {
    color: var(--title-font-color);
    font-size: var(--title-font-size);
    font-weight: bolder;
    padding: 32px 100px;
  }

  .tab_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #eef1f5;
    padding: 4px 48px 4px 100px;

    .tabs_item {
      cursor: pointer;
      color: #86909c;
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      padding: 56px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #c9cdd4;
      }

      &.active {
        color: var(--blue-color);
      }
    }
  }

  .main {
    flex: 2;
    padding: 48px 32px;
    align-self: stretch;
    display: flex;
    flex-direction: column;

    .content_title {
      color: var(--primary-font-color);
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    .content {
      color: var(--gray-font-color);
      font-size: var(--text-font-size);
    }

    .view_detail {
      cursor: pointer;
      width: 100%;
      color: #fff;
      font-size: 22px;
      line-height: 60px;
      text-align: center;
      background-color: var(--blue-color);
      margin-top: auto;
    }
  }

  .right {
    flex: 3;

    .image_cover {
      width: 796px;
      height: 603px;
    }
  }
}
