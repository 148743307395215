.list {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    > div {
      width: 552px;

      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}

.pagination {
  padding: 94px 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > ul {
    margin: 0;
  }
}
