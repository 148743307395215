.container {
  > .title {
    color: var(--title-font-color);
    font-size: var(--title-font-size);
    font-weight: bolder;
    margin-bottom: 32px;
    padding: 0 100px;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #e6ebf1;
    height: 800px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 0.3s ease-in-out;

    .tabs {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      justify-content: space-between;
      visibility: visible;
      position: absolute;
      padding: 80px 100px 0;

      .tab_item {
        width: 500px;
        height: 260px;
        flex: 0 0 calc(20% - 24px);
        height: 64px;
        line-height: 64px;
        cursor: pointer;
        color: #fff;
        font-size: var(--text-font-size);
        background: var(--primary-font-color);
        text-align: center;
        border-radius: var(--border-radius);
        transition: 0.3s ease-in-out;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 16px;

        &.active {
          font-size: var(--text-font-size);
          background-color: rgba(22, 93, 255, 0.9);
          transition: 0.3s ease-in-out;
        }
      }
    }

    .detail {
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
      margin: auto;
      background-color: rgba($color: #000000, $alpha: 0.5);
      width: 1720px;
      height: fit-content;
      padding: 48px;

      .list {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .cell {
          flex: 0 0 48%;
          margin-bottom: 48px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          img {
            width: 48px;
            margin-right: 16px;
          }

          div {
            color: #fff;
            font-size: var(--text-font-size);
            font-weight: 500;
          }
        }

        .row_wrapper {
          &:first-child {
            flex: 5;
          }

          &:last-child {
            flex: 6;
          }

          .cell {
            margin-bottom: 18px;

            &:first-child {
              margin-bottom: 22px;
            }

            img {
              width: 32px;

              &.title_icon {
                width: 32px;
                align-self: center;
              }

              &.circle {
                width: 16px;
                align-self: center;
              }
            }

            div {
              color: #c9cdd4;

              &.label {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
