.article {
  padding: 60px 300px;

  .title {
    background: linear-gradient(-45.56deg, rgba(5.31, 120.17, 255, 1), rgba(21.25, 212.93, 255, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    font-weight: 700;
  }

  .content {
    color: var(--gray-font-color);
    font-size: var(--strong-font-size);
    font-weight: 500;
  }
}

.swiper {
  padding: 0 100px;
}

.solution {
  width: 100%;
  // height: 37.5vw;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px;

  .title {
    color: #fff;
    font-size: var(--title-font-size);
    font-weight: 700;
    margin-bottom: 200px;

    > .p1 {
      font-size: var(--subtitle-font-size);
      font-weight: 500;
      margin-bottom: 32px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .item {
      color: #fff;
      font-size: 32px;
      font-weight: 500;
      padding: 26px 18px;
      background: rgba(255, 255, 255, 0.2);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 70px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.banner {
  width: 100%;
  padding: 84px 160px 20px;
  background-size: contain;
  background-repeat: no-repeat;

  .banner_title {
    color: var(--primary-font-color);
    font-size: 60px;
    text-align: center;
    margin-bottom: 44px;
  }

  .banner_subtitle {
    color: var(--gray-font-color);
    font-size: 22px;
    text-align: center;
    margin-bottom: 64px;
  }

  .list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 20px 20px 100px;
    padding: 40px 100px 60px 100px;
    background: linear-gradient(180deg, rgba(22, 93, 255, 1), rgba(22, 191, 255, 1) 100%), rgb(196, 196, 196);

    .item {
      width: calc(33.33% - 80px);
      width: calc(50% - 160px);
      height: fit-content;
      text-align: center;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: '';
          width: 4px;
          height: 128px;
          background: #fff;
          position: absolute;
          top: 15%;
          right: -60px;
          right: -160px;
        }
      }

      .item_title {
        margin-bottom: 32px;

        .icon {
          width: 80px;
          height: 80px;
          margin-right: 24px;
        }

        .title {
          color: #fff;
          font-size: var(--subtitle-font-size);
          font-weight: 500;
        }
      }

      .item_content {
        color: #fff;
        font-size: var(--text-font-size);
      }
    }
  }
}
