.container {
  padding: 36px 100px;

  .main {
    display: flex;
    flex-direction: column;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;

    .tab_item {
      width: 500px;
      height: 86px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .front {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 4px solid #c9cdd4;
        color: #86909c;
        font-size: var(--text-font-size);

        &.active {
          color: var(--primary-font-color);
          border-color: var(--primary-font-color);
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #0a0c11;

    .content_left {
      flex: 1;
      margin-right: 62px;
      padding: 40px 32px 24px 48px;

      .title {
        color: #fff;
        font-size: var(--subtitle-font-size);
        font-weight: 500;
        margin-bottom: 32px;

        img {
          width: 64px;
          margin-right: 16px;
        }
      }

      .list {
        .cell {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 28px;

          img {
            width: 38px;
            margin-right: 20px;
            margin-top: 2px;
          }

          span {
            color: #c9cdd4;
            font-size: var(--text-font-size);
            line-height: 28px;
          }
        }
      }
    }

    .content_right {
      align-self: center;

      .image_cover {
        width: 890px;
        height: 564px;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 60px 0 40px;

    .title {
      color: var(--title-font-color);
      font-size: var(--title-font-size);
      font-weight: bolder;
    }

    .slogan {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      margin: 0 100px;

      .slogan_title {
        color: var(--primary-font-color);
        font-size: var(--subtitle-font-size);
        font-weight: 500;
        margin-bottom: 20px;
      }

      .slogan_desc {
        color: var(--gray-font-color);
        font-size: var(--text-font-size);
      }
    }

    .contact_us {
      cursor: pointer;
      color: var(--blue-color);
      font-size: 22px;
      line-height: 60px;
      width: 280px;
      text-align: center;
      border: 2px solid var(--blue-color);
      transition: 0.3s ease-in-out;

      &:hover {
        color: #fff;
        background-color: var(--blue-color);
        font-weight: 500;
      }
    }
  }
}
