.product_header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;

  .header_main {
    max-width: 52vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 90px 0 24px;

    .title {
      color: var(--primary-font-color);
      font-size: 64px;
      font-weight: 700;
      margin-bottom: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .description {
      color: var(--gray-font-color);
      font-size: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .breadcrumbs {
      color: var(--gray-font-color);
      font-size: 20px;
      margin-top: auto;

      span {
        cursor: pointer;
      }
    }
  }

  .image_cover {
    width: 348px;
    height: 348px;
  }
}
