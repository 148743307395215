.feature {
  padding: 48px 100px;

  .title {
    color: var(--title-font-color);
    font-size: var(--title-font-size);
    font-weight: bolder;
    margin-bottom: 32px;
  }

  .image_cover {
    width: 100%;
  }
}
