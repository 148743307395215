.container {
  // padding: 120px 0 140px;
  width: 100%;
  // height: 52.1vw;

  .image_cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
