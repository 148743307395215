.top_news {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 92px;

  .image_cover {
    width: 733px;
    height: 536px;
  }

  .top_news_main {
    flex: 1;
    padding: 32px 72px 32px 100px;

    .date {
      color: var(--blue-color);
      font-size: var(--text-font-size);
      font-weight: 500;
      margin-bottom: 24px;
    }

    .title {
      font-size: var(--subtitle-font-size);
      font-weight: 500;
    }

    .btn {
      margin-top: 76px;
      width: 205px;
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: var(--text-font-size);
      background-color: var(--blue-color);
      border-radius: var(--border-radius);
      cursor: pointer;
      text-align: center;
    }
  }
}
