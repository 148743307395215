.container {
  position: relative;

  .mySwiper {
    width: 100%;
    padding: 48px 0 140px;

    :global(.swiper-slide) {
      width: 90%;
      margin-left: 48px;
    }

    :global(.swiper-slide:first-child) {
      padding-left: 100px;
      margin-left: 0;
    }

    .swiper_item {
      display: flex;
      flex-direction: row;

      .image_cover {
        width: 733px;
        height: 536px;
      }

      .main {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 48px;
        background-color: #fff;

        .date {
          color: var(--blue-color);
          margin-bottom: 24px;
        }

        .title {
          color: var(--primary-font-color);
          font-size: var(--subtitle-font-size);
          font-weight: 500;
          margin-bottom: 32px;
        }

        .abstract {
          color: var(--primary-font-color);
          font-size: var(--text-font-size);
        }

        .btn {
          cursor: pointer;
          color: #fff;
          font-size: var(--text-font-size);
          background-color: var(--blue-color);
          padding: 16px 44px;
          margin-top: auto;
        }
      }
    }

    :global(.swiper-pagination) {
      bottom: 60px !important;
    }
  }

  .more {
    cursor: pointer;
    width: fit-content;
    height: 60px;
    color: var(--blue-color);
    border: 1px solid var(--blue-color);
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--text-font-size);
    line-height: 60px;
    position: absolute;
    right: 100px;
    bottom: 48px;
    z-index: 19;

    // &:hover {
    //   color: #fff;
    //   background-color: var(--blue-color);
    // }

    .arrow {
      width: 24pxpx;
      margin-left: 16px;
    }
  }
}
